<template>
  <div>
    <div v-if="!fetching">
      <PageTitle>{{ group.name || '群組名稱' }}</PageTitle>
      <p class="s-mb-10">尚未設定群組網址</p>
      <a class="s-btn s-btn-primary" @click="cancel">確認</a>
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/Page/Liff/GroupBuying/PageTitle'
  import { mapState } from 'vuex'
  import liff from '@line/liff'
  import axios from 'axios';

  export default {
    components: { PageTitle },
    data: () => ({
      fetching: true,
      group: null,
    }),
    computed: {
      ...mapState({
        apiBaseURL: (state) => state.liffGroupBuying.apiBaseURL,
      }),
    },
    mounted() {
      this.fetch();
    },
    methods: {
      async fetch() {
        try {
          let id = this.$route.params.id;

          let response = await axios.get(`${this.apiBaseURL}/groups/${id}`);
          if (response.data.data.group_url) {
            window.location = response.data.data.group_url;
          } else {
            this.group = response.data.data;
            this.fetching = false;
          }
        } catch (error) {
          await this.$alert('讀取群組資料錯誤');
        }
      },
      async cancel() {
        await new Promise(() => liff.closeWindow())
      }
    },
  }
</script>
